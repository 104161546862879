@tailwind base;
@tailwind components;
@tailwind utilities;

.fonts-h1 {
	@apply text-gray-950 font-semibold;
	font-size: 52px;
	line-height: 68px;
}

.fonts-h1-white {
	@apply text-white font-semibold;
	font-size: 52px;
	line-height: 68px;
}

.fonts-h2 {
	@apply leading-10 text-gray-700 font-semibold;
	font-size: 2rem;
}

.fonts-h3 {
	@apply font-sans text-gray-700 font-semibold;
	font-size: 1.5rem;
}

.fonts-h4 {
	@apply font-sans text-gray-700 font-semibold;
	font-size: 22px;
	line-height: 30px;
}

.fonts-h5 {
	@apply text-gray-50 font-sans font-normal;
	font-size: 18px;
	line-height: 26px;
}

.fonts-h5-white {
	@apply text-white font-sans font-normal;
	font-size: 18px;
	line-height: 26px;
}

.fonts-h5-gray {
	@apply text-gray-700 font-sans font-medium;
	font-size: 18px;
	line-height: 28px;
}

.fonts-body-medium {
	@apply text-gray-950 font-sans leading-6 font-medium;
}

.fonts-body {
	@apply text-gray-50 font-sans leading-6 font-normal;
}

.fonts-small {
	@apply text-gray-700 font-sans font-semibold;
	font-size: 14px;
	line-height: 20px;
}

.fonts-black {
	@apply text-[#000000] font-sans font-normal;
	font-size: 14px;
	line-height: 20px;
}

.fonts-small-blue {
	@apply text-primary-600 font-sans font-semibold;
	font-size: 14px;
	line-height: 20px;
}

.fonts-small-yellow {
	@apply text-[#F8B211] font-sans font-semibold;
	font-size: 14px;
	line-height: 20px;
}

.fonts-small-gary {
	@apply text-gray-50 font-sans font-semibold;
	font-size: 14px;
	line-height: 20px;
}

.fonts-small {
	@apply text-gray-50 font-sans;
	font-size: 13px;
	line-height: 18px;
}

.border-small {
	border: 1px solid #eaebf0;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.04);
	border-radius: 5px;
}

.layout {
	@apply px-4 sm:px-16 w-full mx-auto;
}

.icon {
	@apply w-12 h-12 bg-[#EFEFF1] text-[#19459B] rounded-[5px] p-2;
}

.marquee {
	display: flex;
	overflow: hidden;
	user-select: none;
	gap: 2rem;
}

.marquee__group {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 2rem;
	min-width: 100%;
	animation: scroll 60s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
	.marquee__group {
		animation-play-state: paused;
	}
}

.marquee__group img {
	aspect-ratio: 1;
	object-fit: cover;
	border-radius: 1rem;
	object-position: 1px -3px;
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(calc(-100% - 2rem));
	}
}

.markdown h1 {
	@apply leading-10 text-gray-700 font-bold;
	font-size: 2rem;
}

.markdown h2 {
	@apply font-sans text-gray-700 font-semibold;
	font-size: 1.5rem;
}

.markdown h3 {
	@apply font-sans text-gray-700 font-semibold;
	font-size: 18px;
	line-height: 26px;
}

.markdown p ul li {
	@apply text-[#000000] font-sans leading-6 font-normal;
}
.markdown li {
	@apply list-disc;
}

.beta-label {
	background-color: rgb(248, 178, 17);
	box-shadow: rgba(16, 24, 40, 0.04) 0px 1px 2px;
	color: black;
	font-size: small;
	padding: 2px;
	border-radius: 4px;
	margin-left: 4px;
}
